export default [
  {
    slug: "https://cite.mickschroeder.com",
    title: "Citation Generator",
    description:
      "Free and open source software that automatically suggests citations and helps write a bibliography for you.",
    icon: "/images/icon-cite.png",
    links: [
      {
        label: "Try it out",
        url: "https://cite.mickschroeder.com",
      },
      {
        label: "Source code",
        url: "https://github.com/mick-schroeder/schroeder-cite",
      },
    ],
  },
  {
    slug: "https://github.com/mick-schroeder/RxShortages",
    title: "RxShortages",
    description:
      "RxShortages was an open source cross platform mobile application for drug shortages.",
    icon: "/images/icon-rxshortages.png",
    links: [
      {
        label: "Source code",
        url: "https://github.com/mick-schroeder/RxShortages",
      },
    ],
  },
  {
    slug: "https://github.com/mick-schroeder/Medical-Spelling-Dictionary",
    title: "Medical Spelling Dictionary",
    description:
      "Free Spelling dictionary containing medical terms for Microsoft Word / Office.",
    icon: "/images/icon-spell.png",
    links: [
      {
        label: "Download",
        url: "https://github.com/mick-schroeder/Medical-Spelling-Dictionary",
      },
    ],
  },
  {
    slug: "https://webshuffle.mickschroeder.com",
    title: "Web Shuffle",
    description:
      " AI curated \"🔀 Web Shuffle\" button for the best and most influential sites on the web.",
    icon: "/images/icon-randomwebsite.png",
    links: [
      {
        label: "Try it out",
        url: "https://webshuffle.mickschroeder.com",
      },
    ],
  },
  {
    slug: "https://github.com/mick-schroeder/gatsby-schroeder",
    title: "mickschroeder.com",
    description: 'See source code for this website.',
    icon: "/images/icon-mickschroeder.png",
    links: [
      {
        label: "Source code",
        url: "https://github.com/mick-schroeder/gatsby-schroeder",
      },
    ],
  },
]
